import React from 'react';
// import logo from './logo.svg';
import logo from './Taxi-Holdeplass.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          02393 RingTaxi er ikke lenger aktiv.
        </p>
        <p>Finn drosje på&nbsp;
        <a
          className="App-link"
          href="https://www.1881.no/?query=Taxi"
          target="_blank"
          rel="noopener noreferrer"
        >
          1881.no
        </a>
        </p>
      </header>
    </div>
  );
}

export default App;
